<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="widget-content searchable-container grid">
          <div class="">
            <div class="chat-container">
              <div class="hamburger">
                <i class="las la-bars fadeIn" />
              </div>
              <div class="user-container">
                <div class="own-details">
                  <img
                    src="assets/img/profile-16.jpg"
                    v-if="hasMyPhoto"
                  >
                  <i
                    v-else
                    class="las la-user la-3x"
                  />
                  <h3>{{ fullname }}</h3>
                  <p>{{ email }}</p>
                  <div class="dropdown chat-own-setting mt-1">
                    <a
                      class="dropdown-toggle"
                      href="#"
                      role="button"
                      id="customDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ><i class="las la-cog font-20" /></a>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="customDropdown"
                      style="will-change: transform;"
                    >
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Profile</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Chats</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Notifications</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Add People</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Help</a>
                    </div>
                  </div>
                </div>
                <!-- <div class="teams">
                                                <h5 class="chat-headings">Teams <span>Online 4</span></h5>
                                                <div class="team-container">
                                                    <div class="single-team">
                                                        <span class="team-user-img">
                                                            <img src="assets/img/profile-15.jpg" />
                                                        </span>
                                                        <span class="online"></span>
                                                    </div>
                                                    <div class="single-team">
                                                        <span class="team-user-img">
                                                            AP
                                                        </span>
                                                        <span class="offline"></span>
                                                    </div>
                                                    <div class="single-team">
                                                        <span class="team-user-img">
                                                            <img src="assets/img/profile-17.jpg" />
                                                        </span>
                                                        <span class="online"></span>
                                                    </div>
                                                    <div class="single-team">
                                                        <span class="team-user-img">
                                                            <img src="assets/img/profile-18.jpg" />
                                                        </span>
                                                        <span class="online"></span>
                                                    </div>
                                                    <div class="single-team">
                                                        <span class="team-user-img">
                                                            SR
                                                        </span>
                                                        <span class="offline"></span>
                                                    </div>
                                                    <div class="single-team">
                                                        <span class="team-user-img">
                                                            <img src="assets/img/profile-12.jpg" />
                                                        </span>
                                                        <span class="online"></span>
                                                    </div>
                                                </div>
                                            </div> -->
                <div class="user-list-box">
                  <div class="search">
                    <i class="las la-search toggle-search" />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    >
                  </div>
                  <div class="people">
                    <chat-user
                      v-for="(user, index) in otherUsers"
                      :key="index"
                      :user="user"
                    />
                  </div>
                </div>
              </div>
              <div class="chat-details">
                <div class="search mb-3">
                  <i class="las la-search toggle-search" />
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search for a message"
                  >
                </div>
                <div class="chat-details-header">
                  <div class="chat-with">
                    <p>Chat With</p>
                    <h3>Amanda Winston</h3>
                  </div>
                  <div class="chat-with-options">
                    <span
                      class="start-video-call"
                      title="Video Call"
                    ><i class="las la-video" /></span>
                    <span
                      class="start-call"
                      title="Call"
                    ><i class="las la-phone" /></span>
                    <div class="dropdown chat-with-op mt-1">
                      <a
                        class="dropdown-toggle"
                        href="#"
                        role="button"
                        id="customDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ><i class="las la-ellipsis-v" /></a>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="customDropdown"
                        style="will-change: transform;"
                      >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                        >Mute</a>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                        >Pin to top</a>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                        >Report</a>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                        >Export Chat</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chatting-container">
                  <div class="chatting-time">
                    <p>
                      <span>Today</span>
                    </p>
                  </div>
                  <div class="own-chat-container slideInRight">
                    <div class="own-chat">
                      <p>Hey Amanda, Can we get on a quick call ?</p>
                    </div>
                    <span class="own-time">11:25</span>
                  </div>
                  <div class="other-chat-container slideInLeft">
                    <div class="other-chat">
                      <p>Sorry, I'm busy right now. Please call me after 3 PM</p>
                    </div>
                    <span class="other-time">11:28</span>
                  </div>
                  <p class="text-center">
                    <span class="calling-info">Last call duration 16:32</span>
                  </p>
                  <div class="own-chat-container slideInRight">
                    <div class="own-chat">
                      <p>You need to make 3 designs for the project. I'm sending you the reference</p>
                    </div>
                    <span class="own-time">15:25</span>
                  </div>
                  <div class="own-chat-container slideInRight has-file">
                    <div class="has-file">
                      <div class="own-file-container">
                        <span><i class="las la-file-alt" /></span>
                        <div>
                          <p class="file-name">
                            Reference.zip
                          </p>
                          <p class="file-msg">
                            unzip it to view the pdfs
                          </p>
                        </div>
                      </div>
                    </div>
                    <span class="own-time">15:25</span>
                  </div>
                  <div class="other-chat-container slideInRight">
                    <div class="other-chat">
                      <p>Ok</p>
                    </div>
                    <span class="other-time">15:30</span>
                  </div>
                  <div class="other-chat-container slideInLeft">
                    <div class="other-chat">
                      <p>Got It. I will send you the designs by tomorrow</p>
                    </div>
                    <span class="other-time">15:31</span>
                  </div>
                  <div class="own-chat-container slideInRight">
                    <div class="own-chat">
                      <p>Sounds good!</p>
                    </div>
                    <span class="own-time">11:25</span>
                  </div>
                </div>
                <div class="chat-input-container">
                  <div class="cic pointer">
                    <i class="las la-grin-beam" />
                    <input
                      class="chat-text-input"
                      placeholder="Write something here"
                    >
                  </div>
                  <label
                    for="file-input"
                    class="mb-0"
                  >
                    <a
                      class="send"
                      title="Attach a file"
                    ><i class="las la-paperclip" /></a>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    style="display: none;"
                  >
                  <a
                    class="send chat-send"
                    title="Send"
                  ><i class="las la-paper-plane" /></a>
                </div>
              </div>
              <div class="chat-details empty">
                <p>Please select a user to start chatting</p>
              </div>
              <div class="chat-user-details">
                <div class="hide-chat-user-details">
                  <i class="las la-arrow-left" />
                </div>
                <div class="other-details">
                  <img src="assets/img/profile-22.jpg">
                  <h3>Amanda Winston</h3>
                  <p>Amanda@companyname.com</p>
                </div>
                <div class="other-section">
                  <h5 class="chat-headings">
                    Shared Files <span class="pointer">View All</span>
                  </h5>
                  <div class="single-file">
                    <span><i class="las la-file-alt" /></span>
                    <div>
                      <p class="file-name">
                        Reference.zip
                      </p>
                      <p class="file-msg">
                        Today 15:25
                      </p>
                    </div>
                  </div>
                  <div class="single-file">
                    <span><i class="las la-file-alt" /></span>
                    <div>
                      <p class="file-name">
                        tax-slip-2020.zip
                      </p>
                      <p class="file-msg">
                        Yesterday 18:38
                      </p>
                    </div>
                  </div>
                  <div class="single-file">
                    <span><i class="las la-file-alt" /></span>
                    <div>
                      <p class="file-name">
                        Tender_requirement.pdf
                      </p>
                      <p class="file-msg">
                        Yesterday 21:38
                      </p>
                    </div>
                  </div>
                </div>
                <div class="single-chat-option">
                  <a><i class="las la-envelope" /> Send Email</a>
                </div>
                <div class="single-chat-option">
                  <a><i class="las la-volume-mute" /> Mute Notificatios</a>
                </div>
                <div class="single-chat-option">
                  <a><i class="las la-eye-slash" /> Hide Medias</a>
                </div>
                <div class="single-chat-option">
                  <a><i class="las la-thumbtack" /> Pin to top</a>
                </div>
                <div class="single-chat-option">
                  <a><i class="las la-cloud-download-alt" /> Export Chat</a>
                </div>
                <div class="single-chat-option">
                  <a class="text-danger"><i class="las la-eraser" /> Clear All Messages</a>
                </div>
                <div class="single-chat-option">
                  <a class="text-danger"><i class="las la-trash-alt" /> Delete User</a>
                </div>
                <div class="single-chat-option">
                  <a class="text-danger"><i class="las la-ban" /> Block User</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ('../assets/css/apps/chat.css')
import navbar from '../components/navbar.vue'
import ChatUser from '../components/chat/user.vue'
import { mapGetters } from 'vuex'
export default {
    components: {navbar, ChatUser},
    data(){
        return {
            navbarItems: [ { libelle: 'Chat' } ],
            pageIcon: 'la-rocketchat',
            pageTitle: 'Chat',
            pageDescription: 'Discussion instantanée', 
        
        }
    },
    computed: {
      ...mapGetters({
        me: 'auth/me',
        users: 'auth/users'
      }),
      fullname(){
        if(this.me !== null) return this.me.fullname
        return ''
      },
      email(){
        if(this.me !== null) return this.me.email
        return ''
      },
      otherUsers(){
        if(this.me !== null) return [...this.users].filter(item => item.uid !== this.me.uid).sort((a,b) => a.fullname.localeCompare(b.fullname))
        return this.users
      },
      hasMyPhoto(){
        if(this.me !== null) return this.me.photo !== null
        return false
      }
    }

}
</script>

<style>

</style>